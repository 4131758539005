import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { Typography } from "../Typography/Typography"

import * as styles from "./PrivacyPolicyInfo.module.scss"
import { Link } from "gatsby"
import gsap from "gsap"
import clsx from "clsx"

const PrivacyCookieAcceptKey = "POLICY_ACCEPTED"

export default function PrivacyPolicyInfo() {
  const [isAccepted, setIsAccepted] = useState(
    Cookies.get(PrivacyCookieAcceptKey)
  )

  function handleAcceptPolicy() {
    Cookies.set(PrivacyCookieAcceptKey, "true", { expires: 7 })
    setIsAccepted(true)
  }

  useEffect(() => {
    gsap.fromTo(
      ".animated-privacy-info",
      {
        opacity: 1,
        translateY: "100%",
      },
      {
        transitionDelay: 2,
        transitionDuration: 0.5,
        translateY: "0%",
      }
    )
    return () => {}
  }, [])

  if (isAccepted) {
    return null
  }

  return (
    <div className={clsx(styles.PolicyWrapper, "animated-privacy-info")}>
      <Typography variant="body2" style={{ marginRight: "8px" }}>
        We use cookies to ensure that we give you the best experience on our
        website. By continuing to use this website, you agree to our{" "}
        <Link style={{ color: "black" }} to="/privacy-policy">
          Privacy Policy
        </Link>
        .
      </Typography>
      <Typography
        variant="body2"
        onClick={handleAcceptPolicy}
        style={{ color: "rgba(70, 79, 252, 1)", cursor: "pointer" }}
      >
        Got it!
      </Typography>
    </div>
  )
}
