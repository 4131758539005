import React, { useState, useEffect } from "react"
import Navigation from "../Navigation"
import PageTransition from "./PageTransition"
import PrivacyPolicyInfo from "src/components/PrivacyPolicyInfo/PrivacyPolicyInfo"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../components/Backgrounds")
)

const NavigationStateWrapper = ({
  render,
  locationPath,
  scrollTo,
  locationKey,
}) => {
  const [navigationOpened, setNavigationOpened] = useState(false)

  useEffect(() => {
    setNavigationOpened(false)
  }, [locationPath, scrollTo, locationKey])

  return render({ navigationOpened, setNavigationOpened })
}

export const wrapPageElement = ({ element, props }) => {
  const { location } = props
  const { state } = location
  const scrollTo = state?.scrollTo || ""
  const key = state?.key || ""
  const isSSR = typeof window === "undefined"

  return (
    <NavigationStateWrapper
      locationPath={location.pathname}
      scrollTo={scrollTo}
      locationKey={key}
      render={({ navigationOpened, setNavigationOpened }) => (
        <div>
          {!isSSR && (
            <React.Suspense fallback={<div></div>}>
              <ClientSideOnlyLazy />
            </React.Suspense>
          )}
          <Navigation
            navigationOpened={navigationOpened}
            setNavigationOpened={setNavigationOpened}
            siteTitle={`Title`}
          />
          <PageTransition
            navigationOpened={navigationOpened}
            setNavigationOpened={setNavigationOpened}
            location={location}
          >
            {element}
          </PageTransition>
          <PrivacyPolicyInfo />
        </div>
      )}
    />
  )
}
