import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./Navigation.module.scss"
import ThemeContext, { Themes } from "src/theme/context"
import MobileButton from "./MobileButton"
import clsx from "clsx"
import gsap from "gsap"

const NavbarThemes = Object.freeze({
  dark: "DARK",
  default: "DEFAULT",
})

function toggleBodyScroll(on = true) {
  document.querySelector("html").style.overflowY = on ? "initial" : "hidden"
}

const getNavbarTheme = currentGlobalTheme => {
  switch (currentGlobalTheme) {
    case Themes.secondaryDark:
    case Themes.dark:
    case Themes.secondary:
      return NavbarThemes.dark

    case Themes.light:
    case Themes.default:
    default:
      return NavbarThemes.default
  }
}

export const Navigation = ({
  siteTitle,
  navigationOpened,
  setNavigationOpened,
}) => {
  const { theme } = useContext(ThemeContext)
  const animationRef = useRef(null)
  const [showNavbar, setShowNavbar] = useState(true)

  const navbarTheme = getNavbarTheme(theme)

  const ButtonListClass =
    navbarTheme === NavbarThemes.dark
      ? styles.DarkButtonList
      : styles.ButtonList

  const LinkClass =
    navbarTheme === NavbarThemes.dark
      ? styles.SimpleLinkLight
      : styles.SimpleLink

  const MobileButtonClass =
    navbarTheme === NavbarThemes.dark ? styles.LightStroke : styles.DarkStroke

  const EmailClass =
    navbarTheme === NavbarThemes.dark
      ? styles.MobileOnlyEmailLight
      : styles.MobileOnlyEmail

  const BlesuLogo =
    navbarTheme === NavbarThemes.default ? (
      <img src="/blesulogo.png" alt="Blesu logo" />
    ) : (
      <img src="/blesulogo-light.png" alt="Blesu logo" />
    )

  useEffect(() => {
    let prev = 0
    const scrollCallback = e => {
      const scrollTop = window.scrollY
      setShowNavbar(scrollTop < prev || scrollTop < 40)
      prev = scrollTop
    }

    window.addEventListener("scroll", scrollCallback)

    return () => {
      window.removeEventListener("scroll", scrollCallback)
    }
  }, [])

  useEffect(() => {
    toggleBodyScroll(!navigationOpened)

    if (navigationOpened && animationRef.current) {
      animationRef.current.play()
    }

    if (!navigationOpened && animationRef.current) {
      animationRef.current.reverse()
    }

    if (!animationRef.current && window.screen.width <= 870) {
      animationRef.current = gsap
        .timeline({ paused: true })
        .to(".animated-menu-buttons-wrapper", {
          x: "+=-100%",
          duration: 0,
          delay: 0.4,
        })
        .from(".animated-menu-button", {
          opacity: 0,
          y: 30,
          duration: 0.4,
          ease: "power2.out",
          stagger: 0.1,
        })
    }
  }, [navigationOpened])

  return (
    <header
      className={
        showNavbar
          ? styles.NavigationContainerVisible
          : styles.NavigationContainer
      }
    >
      <div className={styles.LogoWrapper}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
          state={{ scrollTo: "" }}
        >
          {BlesuLogo}
        </Link>
      </div>
      <nav>
        <MobileButton
          opened={navigationOpened}
          className={MobileButtonClass}
          onClick={() => setNavigationOpened(!navigationOpened)}
        />
        <ul className={clsx(ButtonListClass, "animated-menu-buttons-wrapper")}>
          <li className={clsx("animated-menu-button", styles.MobileOnlyLabel)}>
            Menu
          </li>
          <li className={clsx("animated-menu-button", LinkClass)}>
            <Link to="/services" state={{ scrollTo: "" }}>
              What we do
            </Link>
          </li>
          <li className={clsx("animated-menu-button", LinkClass)}>
            <Link to="/process" state={{ scrollTo: "" }}>
              How we do it
            </Link>
          </li>
          <li className={clsx("animated-menu-button", LinkClass)}>
            <Link to="/" state={{ scrollTo: "projects-section" }}>
              Our projects
            </Link>
          </li>
          <li
            className={clsx("animated-menu-button", styles.HighlightedButton)}
          >
            <Link to="/contact">Contact us</Link>
          </li>

          <li className={clsx("animated-menu-button", styles.MobileOnlyLabel)}>
            Get in touch
          </li>
          <a
            href="mailto:hello@blesu.com"
            className={clsx("animated-menu-button", EmailClass)}
          >
            hello@blesu.com
          </a>
        </ul>
      </nav>
    </header>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}
