import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Typography.module.scss"
import clsx from "clsx"

export const Typography = React.forwardRef(
  ({ variant = "p", children = "", className = "", ...rest }, ref) => {
    const Component = variant
    return (
      <Component
        ref={ref}
        className={clsx(styles.AnimatedColor, className)}
        {...rest}
      >
        {children}
      </Component>
    )
  }
)

Typography.propTypes = {
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "p", "span"])
    .isRequired,
  children: PropTypes.node.isRequired,
}
