/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { wrapPageElement as pageWrapper } from "src/components/AppWrappers/wrapPageElement"
import { wrapRootElement as rootWrapper } from "src/components/AppWrappers/wrapRootElement"
import { killAnimations } from "src/helpers/killTransitions"

export const onPreRouteUpdate = ({}) => {}

export const wrapPageElement = pageWrapper
export const wrapRootElement = rootWrapper

export const onRouteUpdate = apiCallbackContext => {
  const { location, prevLocation } = apiCallbackContext

  if (prevLocation && prevLocation.pathname !== location.pathname) {
    killAnimations()
  }
}
