import React, { useState, useCallback } from "react"
import ThemeContext, { Themes } from "src/theme/context"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import PixiPlugin from "gsap/PixiPlugin"
import * as PIXI from "pixi.js"

if (typeof window !== "undefined" && window) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)
  PixiPlugin.registerPIXI(PIXI)

  gsap.registerPlugin(PixiPlugin)
}

function Wrapper({ children }) {
  const [theme, setTheme] = useState(Themes.default)
  const changeTheme = useCallback(newTheme => {
    setTheme(newTheme)
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>
}
