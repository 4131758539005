// extracted by mini-css-extract-plugin
export var NavigationContainer = "Navigation-module--NavigationContainer--O6Arm";
export var NavigationContainerVisible = "Navigation-module--NavigationContainerVisible--2EgGe";
export var ButtonList = "Navigation-module--ButtonList--ZUJeD";
export var DarkButtonList = "Navigation-module--DarkButtonList--o0YJP";
export var LogoWrapper = "Navigation-module--LogoWrapper--36lu4";
export var DarkStroke = "Navigation-module--DarkStroke--1UrTc";
export var LightStroke = "Navigation-module--LightStroke--2l9wq";
export var MobileOnly = "Navigation-module--MobileOnly--JWiH3";
export var MobileOnlyEmail = "Navigation-module--MobileOnlyEmail--3uMkG";
export var MobileOnlyEmailLight = "Navigation-module--MobileOnlyEmailLight--2blcG";
export var MobileOnlyLabel = "Navigation-module--MobileOnlyLabel--UjeNn";
export var SimpleLink = "Navigation-module--SimpleLink--3EVpE";
export var HighlightedButton = "Navigation-module--HighlightedButton--ti7ih";
export var SimpleLinkLight = "Navigation-module--SimpleLinkLight--2nUEg";