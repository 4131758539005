import { createContext } from "react"

export const Themes = Object.freeze({
  default: "default",
  light: "light",
  dark: "dark",
  secondary: "secondary",
  secondaryDark: "secondary-dark",
})

export const ThemeCategories = Object.freeze({
  light: "light",
  dark: "dark",
})

export const getThemeCategory = theme => {
  switch (theme) {
    case Themes.dark:
    case Themes.secondary:
    case Themes.secondaryDark:
      return ThemeCategories.light
    default:
      return ThemeCategories.dark
  }
}

const ThemeContext = createContext({
  theme: Themes.default,
  changeTheme: newTheme => {},
})

export default ThemeContext
