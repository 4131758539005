import React from "react"
import PropTypes from "prop-types"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import * as TransitionStyles from "./PageTransitionStyles.module.scss"
import * as styles from "./PageTransition.module.scss"

const PageTransition = ({
  children,
  location,
  navigationOpened,
  setNavigationOpened,
}) => {
  const { pathname = "" } = location

  return (
    <div
      className={
        navigationOpened ? styles.MobileContent : styles.MobileContentVisible
      }
    >
      <TransitionGroup>
        <CSSTransition
          key={pathname}
          unmountOnExit
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false)
          }}
          classNames={TransitionStyles}
          timeout={{
            appear: 1000,
            enter: 1000,
            exit: 0,
          }}
        >
          {children}
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default PageTransition
